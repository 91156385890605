import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL

const token = localStorage.getItem('jp_token')



export  const dashboarFetch = async () => {
    const resp = await axios.get(apiUrl+'mgt/dashboard',{headers:{'Authorization': `Token ${token}`}})  
    return resp.data
}