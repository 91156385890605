import { Link } from "react-router-dom";
const SelectAction = ({setType}) => {
    return ( 
                <>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    
                <div className="border-1 p-3 py-5 border-blue-400 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
                {/* body */}
        
        
              
                    <div className="w-full text-left p-3 flex justify-between pb-3 border-b">
                        <p className="w-full text-xl font-bold  text-gray-600">Select Action</p>
                       <Link to="/" ><p className="font-sm font-bold text-gray-400"><span class="material-icons-outlined">
close
</span></p></Link> 
                    </div>

                    <div className="w-full flex flex-wrap py-4">
                        <div className="w-full lg:w-1/2 p-2">
                            <button className="w-full py-5 my-1 rounded-lg border font-bold text-blue-600 border-blue-600" onClick={()=>setType('go')}>Proceeding Trucks</button>
                        </div>
                        <div className="w-full lg:w-1/2 p-2">
                            <button className="w-full py-5 my-1 border rounded-lg font-bold text-green-500  border-green-500" onClick={()=>setType('return')} >Returning Trucks</button>
                        </div>
                    </div>
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40"></div>
        </>        
     );
}
 
export default SelectAction;