import Lottie from "react-lottie";
import { loginAPI, userDetailsAPI } from "../api/loginApi";
import loginLottie from '../assets/lotties/40395-tap-card-for-nfc.json'
import authNFC from '../assets/lotties/46347-nfc-processing.json'
import errorNFC from '../assets/lotties/49428-nfc-fail.json'
import { useDispatch,useSelector } from "react-redux";
import { loginStates } from "../features/loginSlice";
import {useState,useEffect} from "react"
import { Redirect } from "react-router";

const Login = () => {

  const dispatch = useDispatch()

  const loginState = useSelector(loginStates)

  const isLoading = loginState.isLoading
  const error = loginState.failed
  const isAuth = loginState.isAuth
  const errorM = loginState.error


  const [nfc,setNfc] = useState(null)


  const [nfcState,setState] = useState(null)

  const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loginLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      const defaultOptions02 = {
        loop: true,
        autoplay: true,
        animationData: authNFC,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      const defaultOptions03 = {
        loop: true,
        autoplay: true,
        animationData: errorNFC,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };


useEffect(() => {

startScanning()

}, [])	


useEffect(()=> {
  ! error && startScanning()
},[error])


  const startScanning = () => {

    if ('NDEFReader' in window){
  
    const reader = new window.NDEFReader();
    const contr = new AbortController();

    new Promise  (async (resolve,reject)=>{
      try {
        await reader.scan({signal: contr.signal});
        // console.log('Started')

    
        reader.addEventListener("reading", ({ message, serialNumber }) => {
          
          contr.abort()
          // const nfcSerial = `${serialNumber}`;
          handleSubmit(`${serialNumber}`)
        });
        
  
      } catch (error) {
          // alert(error)
        console.log(error)
      }
    })

 


  }else{
    console.log('Not Found')
    setState('Not Supported')
  }
    

    }




    //  submitting nfc



    const handleSubmit = (nfc) => {
      console.log('nfc is ',nfc)
      const d =  dispatch(loginAPI({'tag':nfc}))
    }


    useEffect(() => {
      !isAuth && 
      localStorage.getItem('jp_token') &&
      dispatch(userDetailsAPI())
    }, [isAuth])

    



    return ( 
      <>
      {isAuth ? <Redirect to={{
        pathname: "/",
    }} /> :


                <>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    
                <div className="border-1 p-3   rounded-3xl shadow-lg relative flex flex-col w-full bg-white py-6 outline-none focus:outline-none">




        {
        

        error ?
        <>
        {/* <p>Loadingss</p>   */}
          <p className="w-full py-3 font-bold  text-2xl text-gray-600">Invalid NFC </p>
    
    <div className="flex item-center">
    <Lottie 
                    options={defaultOptions03}
                    height={400}
                    width={400}
                    />   

    </div>
  </>

        :
        
        
        isLoading ? 
      <>
            {/* <p>Loadingss</p>   */}
              <p className="w-full py-3 font-bold  text-gray-600">Authenticating Officer</p>
        
        <div className="flex item-center">
        <Lottie 
                        options={defaultOptions02}
                        height={400}
                        width={400}
                        />   

        </div>
      </>

          :

        <>
          <p className="w-full py-3 font-bold  text-gray-600 text-2xl"  onClick={startScanning} >Scan Officer's NFC <br /> {nfcState === 'Not Supported' && <span className="text-xs font-bold border border-blue-600 text-blue-600 ml-3 inline-flex py-1 px-3  rounded align-middle "> NFC Not Supported </span> }   </p>
        
        <div className="flex item-center">
        <Lottie 
          onClick={()=>{console.log('starting....')}}
                        options={defaultOptions}
                        height={400}
                        width={400}
                        />   

        </div>


{/* 
        <div className="w-full lg:w-1/2 mx-auto mt-3">
                <input onChange={(e)=>setNfc(e.target.value)} type="text" placeholder="Enter NFC" className="p-3 my-3 border border-1 rounded text-sm  border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-4 px-3 w-full border-gray-200" />

                <button onClick={()=>{
                  handleSubmit(nfc)
                }} className="my-3 font-bold bg-blue-600 py-4 rounded text-white w-full mx-auto ">Submit NFC</button>

              </div> */}


        </>
          
      }

      
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40"></div>
       </>
       }
        </>
     );
}
 
export default Login;