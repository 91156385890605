import './App.css';
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom'
import Home from './pages/home';
import Trucks from './pages/truck';
import Checklist from './pages/checklist';
import Driver from './pages/driver';
import Login from './pages/login';
import PrivateRoute from './components/universal/PrivateRoute';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

function App() {

  const queryClient = new QueryClient()

  return (

    <QueryClientProvider client={queryClient}>
    <Router>
      <div className="App">
        <Switch>
        <Route exact path="/login">
                <Login />
          </Route>
          <PrivateRoute exact path="/">
                <Home />
          </PrivateRoute>
          <PrivateRoute exact path="/scanTruck">
                <Trucks />
          </PrivateRoute>
          <PrivateRoute exact path="/checkList">
                <Checklist />
          </PrivateRoute>
          <PrivateRoute exact path="/driver">
                 <Driver />
          </PrivateRoute>
        </Switch>

    
      </div>

    </Router>

</QueryClientProvider>

  );
}

export default App;
