export const SYellowLoader = () => {
    return ( 
        <div class="small-spinner yellowSpinner"></div>
     );
}

export const SRedLoader = () => {
    return ( 
        <div class="small-spinner redSpinner"></div>
     );
}
 



export const SBlueLoader = () => {
    return ( 
        <div class="small-spinner blueSpinner"></div>
     );
}


export const SGreenLoader = () => {
    return ( 
        <div class="small-spinner greenSpinner"></div>
     );
}
