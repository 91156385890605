import Lottie from "react-lottie";
import errorNFC from '../../../assets/lotties/49428-nfc-fail.json'

const ErrorTruck = ({error}) => {


      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: errorNFC,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return ( 
                <>
                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    
                <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
                {/* body */}

        
                      <>
        {/* <p>Loadingss</p>   */}
          <p className="w-full py-3 font-bold ">{error} </p>
    
    <div className="flex item-center">
    <Lottie 
                    options={defaultOptions}
                    height={400}
                    width={400}
                    />   

    </div>
  </>
        
        
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40"></div>
        </>
     );
}
 
export default ErrorTruck;