
import { useSelector,useDispatch } from "react-redux";
import {Link} from "react-router-dom";
import { userDetails } from "../../features/userSlice";
import {createImageFromInitials,getRandomColor} from "../randomPics" 
import { useState } from "react";
import { logoutUser } from "../../features/userSlice";

const Navbar = () => {

  const userStates = useSelector(userDetails)

  const dispatch = useDispatch()

  const checkpoint = userStates.checkpoint
  const currentUser = userStates.names

  const [showDrop,setShowDrop] = useState(false)


    return ( 
      
<nav className="shadow-sm">
  <div className="mx-auto px-2  lg:px-8">
    <div className="relative flex items-center justify-between h-16">
      <div className="absolute inset-y-0 left-0 flex items-center ">
      
        
      </div>
      <div className="flex-1  flex items-center sm:items-stretch sm:justify-start text-left">
        <div className="flex items-start">
    <Link to="/" >   <p className="font-bold cursor-pointer text-left" >Journey Plan <span className="bg-blue-600 text-white ml-2 text-xs font-bold px-4 py-2 rounded-full capitalize ">{checkpoint === null ? '#' : checkpoint} Station</span> </p>        </Link> 
        </div>

      </div>


      <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        

       
        <div className="ml-3 relative">
          <div>
            <button onClick={()=>setShowDrop(!showDrop)} type="button" className="text-dark flex text-sm rounded-full focus:outline-none" id="user-menu-button"  aria-expanded="false" aria-haspopup="true">
              <span className="sr-only">Open user menu</span>
              {
                  <img className="rounded-full" src={createImageFromInitials(40,  currentUser  == '' ? 'Not Available' : currentUser, getRandomColor()) } alt="" /> 

                  }      </button>
          </div>




      {showDrop ? (
          <div  className="origin-top-right absolute right-0 font-bold mt-2 w-48 rounded-md shadow-lg py-3 px-4 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" id="user-menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">

        

            <a className="block px-4 py-2 text-sm text-gray-700 cursor-pointer" role="menuitem" tabindex="-1" id="user-menu-item-2" onClick={()=>{dispatch(logoutUser())}} >

            <span className="material-icons-outlined inline-flex align-bottom mr-2" >
              logout
            </span>

       Log Out</a> 
              
          </div>
    ): ''}

        </div>
      </div>

    </div>
  </div>


</nav>
   
     );
}
 
export default Navbar;