import axios from "axios"
import { loginPending,loginSuccess,loginFailed,resetFailed } from "../features/loginSlice"
import { logoutUser,successUser } from "../features/userSlice"
const apiUrl = process.env.REACT_APP_API_URL


export const  loginAPI = (tag) => dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
            dispatch(loginPending())
            const res = await axios.post(apiUrl+'mgt/login/',tag)
            if (res.status === 200) {
                localStorage.setItem('jp_token',res.data.token)
                dispatch(loginSuccess())
                dispatch(userDetailsAPI())
            } 

        }catch(error){
            dispatch(loginFailed('Invalid NFC'))
            setTimeout(() => {
                dispatch(resetFailed())
            }, 3000);
        }
    } )    
}

export const logout = () => dispatch => {
    dispatch(logoutUser())
}


export const userDetailsAPI = () => dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
            const token = localStorage.getItem('jp_token')
    const res = await axios.get(apiUrl+'mgt/userDetails',{headers: {"Authorization": `Token ${token}`}})
    if (res.status == 200){
        dispatch(successUser(res.data))
    }
        }catch (e){
            // token faulty
            dispatch(logoutUser())
            localStorage.removeItem('jp_token')
        }
    })
    
}