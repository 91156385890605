import { errorDrivers, loadingDrivers, successDrivers,clearDriverError } from "../features/driverSlice"
import axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL


export const fetchDrivers = () => dispatch => {
    return new Promise (async (resolve,reject) => {
        try {
            dispatch(loadingDrivers())
            const res = await axios.get(apiUrl+'jp/drivers')
            if (res.status == 200){
                dispatch(successDrivers(res.data))
            }else{
                dispatch(errorDrivers(res.data.msg))
                setTimeout(() => {
                        dispatch(clearDriverError())
                }, 2000);
            }
        } catch (e) {
            console.log(e)
        }
    })
}