import ConfirmDriver from "../components/modals/driver/confirmDriver";
import Navbar from "../components/universal/Navbar";

const Driver = () => {
    return ( 
        <>
        <Navbar />
        <ConfirmDriver />
        </>

     );
}
 
export default Driver;