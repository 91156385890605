import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import { useSelector,useDispatch } from "react-redux";
import { clearChecklist, jpState } from "../../../features/jpSlice";
import { Redirect } from "react-router";
import { cancelList, checklistSubmit } from "../../../api/jpApi";
import loadingLottie from '../../../assets/lotties/lf30_editor_dzzrxhzp.json'
import successLottie from '../../../assets/lotties/74877-success.json'
import user_avatar from '../../../assets/img/user.png'
import { useState } from "react";
import ChangeDriver from "./changeDriver";

const ConfirmDriver = () => {

    const jpStates = useSelector(jpState)

    const [changeDriver,setChangeDriver] = useState(false)

    const dispatch = useDispatch()

    const driver = jpStates.driver
    const checklist = jpStates.checklist

    const success = jpStates.success
    const loading = jpStates.loading
    const truck = jpStates.truck


    const [loadingImage,setLoadingImage] = useState(true)


      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      const defaultOptions01 = {
        loop: true,
        autoplay: true,
        animationData: successLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };


      const imageonload = () => {
        setLoadingImage(false)
        console.log('loading is now ',loadingImage )
      }
    

    return ( 
      <>

      {
        truck === null ? 
            <Redirect to={{
                pathname: "/scanTruck",
            }} /> 
        :

 

    
      
        checklist === null ?
            <Redirect to={{
                pathname: "/checkList",
            }} /> 
            :


                <>

                <div
              className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                    
                <div className="border-1 p-3 py-5  border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        
                {/* body */}


                {loading ? 
                


<div className="container my-2 ">
    <div className="my-2 text-center font-bold text-lg text-blue-700">Submiting CheckList </div>
    <div className="w-full flex items-center">
              <Lottie 
                            options={defaultOptions}
                            height={200}
                            width={200}
                            />   
    </div>
</div>

                  :



              

                success  ?


                  <>
  <div className="container my-2 ">
    <div className="my-2 text-center font-bold  text-green-6W00">Checklist Successfully Submitted </div>
    <div className="w-full flex items-center">
              <Lottie 
                            options={defaultOptions01}
                            height={200}
                            width={200}
                            />   
    </div>
</div>
                  </>


                  :





                <>





            {changeDriver ? 
             
             <ChangeDriver  setChangeDriver={setChangeDriver} />
             :
              
         


             <>

                  { loadingImage  &&

<div className="w-full flex items-center">
<Lottie 
              options={defaultOptions}
              height={200}
              width={200}
              />   
</div>

                  }

                  
                  <div style={{display: loadingImage ? 'none': 'block'}}   className="w-48 h-48 my-5  rounded-full mx-auto border-blue-600 border-4">
                  <img onLoad={imageonload} src={ driver.driver_face != "N/A" ? 'https://cpk.tmflimited.co.ke/driver_faces/'+driver.driver_face : user_avatar } alt="" className="rounded-full" />
                </div>

              


                  
                

                  

                    <div className="w-full py-3">
                        <p className="text-gray-600 text-2xl font-bold">Confirm Driver As {driver.d_name} ? </p>
                    </div>
        

                    <div className="w-full flex justify-between px-7 py-8">
                        <button className="bg-blue-500 text-white font-sm py-3 font-bold px-10 rounded focus:outline-none" onClick={()=>dispatch(checklistSubmit(checklist))}  >Yes</button>
    
          <button className="bg-red-500 text-white font-sm  font-bold py-3 px-10 rounded focus:outline-none" onClick={()=>setChangeDriver(true)} >No</button>
                    </div>
</>

}

                    </>
        

                }
        
        
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40"></div>
        </>

                
      
      

                
}
</>
   
     );
}
 
export default ConfirmDriver;