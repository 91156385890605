import {createSlice} from "@reduxjs/toolkit"

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        names:null,
        checkpoint:null,
    },
    reducers: {
        successUser: (state,action)=> {
            state.names  = action.payload.names
            state.checkpoint = action.payload.checkpoint
        },
        logoutUser:(state)=>{
            state.names = null
            state.checkpoint = null
            localStorage.removeItem('jp_token')
        }
    }
})




export const {successUser,logoutUser}  = userSlice.actions
export const userDetails  = (state) => state.user
export default userSlice.reducer