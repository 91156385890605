
import {useState,useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Lottie from "react-lottie";
import { fetchDrivers } from '../../../api/driver';
import loadingLottie from '../../../assets/lotties/lf30_editor_dzzrxhzp.json'
import { driverDetails } from '../../../features/driverSlice'
import { clearChecklist, jpState } from "../../../features/jpSlice";
import { cancelList, checklistSubmit } from "../../../api/jpApi";

import { useRef } from "react"




const ChangeDriver = (props) => {

    const [driverSelected,setDriver] = useState()


    const cells = useRef(null)


    const dispatch = useDispatch()

    const jpStates = useSelector(jpState)


    const driverD = useSelector(driverDetails)



    const drivers = driverD.drivers
    const loading_drivers = driverD.loading


    const [reason,setReason] = useState(null)

    const checklist = jpStates.checklist


    const handleSearch = (e) =>{
        const cards = cells.current.children
    
        const searchText = e.target.value.toLowerCase()
    
        for (const card  of cards){
            card.textContent.toLowerCase().indexOf(searchText) != -1 ? card.classList.remove('hidden') : card.classList.add('hidden') 
        }
      
    
        
    }


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };


    useEffect(() => {
        drivers == null && 
        dispatch(fetchDrivers())
    }, []);
    


    const submitNewDriver = () => {

        if(reason != null && driverSelected != null ) {
            console.log('submititng');
            
            dispatch(checklistSubmit({...checklist,driver:driverSelected,reason:reason}))
        }else{
            console.log('Eror')
        }
        
       

    }





    return ( 

        <div className="w-full">

            { 
                loading_drivers ? 
                <div className="w-full flex items-center h-48 justify-center">
                   <div className="w-full flex items-center">
<Lottie 
              options={defaultOptions}
              height={200}
              width={200}
              />   
</div>
                </div>


:

<div className="p-4">

 

<div className="p-3 flex justify-between items-center mb-2">
<p className="font-bold  text-xl text-gray-600 text-left">Select Correct Driver</p>


<div onClick={()=>props.setChangeDriver(false)}  className="">
    <span className="material-icons-outlined">close</span>
</div>

</div>



{drivers != null && 
          <div className="bg-white shadow p-2 flex mb-6">
          <span className="w-auto flex justify-end items-center text-gray-500 p-2">
              <i className="material-icons-outlined text-3xl">search</i>
          </span>
          <input onChange={handleSearch} className="w-full rounded p-2 text-sm focus:outline-none searchMeals" type="text" placeholder="Search Driver "/>
      </div>

}



<div className="w-full flex flex-wrap max-h-72 overflow-y-scroll" ref={cells} >

                {drivers != null && 

                    drivers.map(x=>
         <button onClick={()=>setDriver(x.driver_id)} className={`py-2 m-1 font-bold px-4 rounded
                         
                         ${driverSelected == x.driver_id ? 'text-blue-600 border-blue-600'  : 'text-gray-400 border-gray-400'}                    
                         border text-sm uppercase`}>
                            {x.d_name}
                        </button>
                    
                        )

                }
                

</div>




{driverSelected != null && 

    <div className="w-full text-left mt-6">
    <label htmlFor="" className="py-2 text-left font-bold text-gray-600" > Reason</label>
<textarea onKeyUp={(e)=>setReason(e.target.value)} type="text" placeholder="" className="p-3 my-3 border border-1 rounded text-sm  border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-4 px-3 w-full border-gray-200" ></textarea>

 

    <div className="w-full lg:w-2/3 mx-auto">
        <button onClick={submitNewDriver} className="py-3 w-full font-bold text-sm text-white bg-blue-600 rounded">Submit</button>
    </div>

    </div>
}



</div>


            }



        </div>

     );
}
 
export default ChangeDriver;