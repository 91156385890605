import AuthTruck from "../components/modals/truck/authTruck";
import ScanTruck from "../components/modals/truck/scanTruck";
import Navbar from "../components/universal/Navbar";
import { Redirect } from "react-router";
import { useState } from "react";
import SelectAction from "../components/modals/truck/selectAction";
import { jpState } from "../features/jpSlice";
import { useDispatch,useSelector } from "react-redux";
import ErrorTruck from "../components/modals/truck/errorTruck";

const Trucks = () => {

    const jpStates = useSelector(jpState)

    const loading = jpStates.loading
    const failed = jpStates.failed  
    const error = jpStates.error
    const driver = jpStates.driver
    const truck = jpStates.truck

    console.log(loading)
   
    const [type,setType] = useState(null)


    return (  
        <>

    {driver !== null ?
    
    <Redirect to={{
        pathname: "/checklist",
    }} /> 
    
    :
    
    
    <>
        <Navbar />

        {type == null ? 
           <SelectAction setType={setType} />

            :     
            !loading ?
            !failed ?
            <ScanTruck  type={type} />
            :
            <ErrorTruck error={error} />
            :
            <AuthTruck />
        }

</>
}
    
        </>
    );
}
 
export default Trucks;