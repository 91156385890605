import Navbar from "../components/universal/Navbar";
import {Link} from 'react-router-dom'
import  {useQuery} from 'react-query'
import { dashboarFetch } from "../api/dashboard";
import { SBlueLoader, SGreenLoader, SYellowLoader } from "../components/universal/loader";
const Home = () => {


    const {isError,isSuccess,isLoading,data,error} = useQuery('dasboard_data',
        dashboarFetch, {
            staleTime:10000
        }
    )
 
    return ( 
        <>
        <Navbar/>
        
        <div className="mt-3 w-full">

        <div className="w-full flex flex-wrap p-3">



            <div className="w-full lg:w-1/4 mx-auto  p-4 ">
                <div className="w-full shadow-sm border-2 border-blue-600 text-blue-600 rounded-lg   py-3  px-6 ">
                    <div className="w-full flex justify-between mt-6">
                        <p className="font-bold" >Today's Proceeding Trucks</p>
                        <div className="flex border-2 border-blue-600  rounded-full py-3 px-4">
                            <span class="material-icons-outlined text-3xl">
                            arrow_forward_ios
</span>
                            </div>
                    </div>


                    <div className="w-full flex items-start pb-4 -mt-4">
                        <div className="bg-blue-600 text-white rounded-full py-3 px-9 font-bold inline-flex text-lg ">
                        {isLoading ? <SBlueLoader /> : <p> {data && `${data.proceed} Trucks`}</p>}
                        </div>
                    </div>

                </div>
            </div>

            <div className="w-full lg:w-1/4 mx-auto  p-3 ">
                <div className="w-full shadow-sm border-2 border-green-600 text-green-600 rounded-lg   py-3  px-6 ">
                    <div className="w-full flex justify-between mt-6 ">
                        <p className="font-bold" >Today's Returning Trucks</p>

                        <div className="flex border-2 border-green-600  rounded-full py-3 px-4">
                            <span class="material-icons-outlined text-3xl">
                            arrow_back_ios
</span>
                            </div>
                        {/* <p className="font-bold text-3xl">0</p> */}
                    </div>

                    <div className="w-full flex items-start pb-4 -mt-4">
                        <div className="bg-green-600 text-white rounded-full py-3 px-9 font-bold inline-flex text-lg ">
                        {isLoading ? <SGreenLoader /> : <p> {data && `${data.return} Trucks`} </p>}

                        </div>
                    </div>

                </div>
            </div>



            <div className="w-full lg:w-1/4 mx-auto  p-3 ">
                <Link to="/scanTruck" >
                <div className="w-full shadow-sm border-2  border-yellow-600 text-yellow-600 rounded-lg   py-3  px-6 ">
                    <div className="w-full flex justify-between my-6">
                        <p className="font-bold" >Scan Trucks</p>

                            <div className="flex border-2 border-yellow-600  rounded-full p-3">
                            <span class="material-icons-outlined text-3xl">
nfc
</span>
                            </div>

                    </div>
                </div>
                </Link>
            </div>

        </div>

        </div>

        </>
     );
}
 
export default Home;