import {createSlice} from "@reduxjs/toolkit"

export const driverSlice = createSlice({
    name: 'drivers',
    initialState: {
        drivers: null,
        loading:false,
        error: null
    },
    reducers: {
        loadingDrivers: (state) =>{
            state.loading = true
        },
        successDrivers: (state,action)=> {
            state.drivers  = action.payload
            state.loading = false
        },
        errorDrivers:(state,action)=>{
            state.loading = false
            state.error = action.payload
        },
        clearDriverError:(state)=>{
            state.error = null
        }
    }
})




export const {loadingDrivers,successDrivers,errorDrivers,clearDriverError}  = driverSlice.actions
export const driverDetails  = (state) => state.drivers
export default driverSlice.reducer