import Navbar from "../components/universal/Navbar";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import CheckModal from "../components/modals/checklist/checkmodal";
import { jpState } from "../features/jpSlice";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";


const Checklist = () => {

    const jpStates = useSelector(jpState)

    const truck = jpStates.truck

    return ( 
        <>

        {truck === null  ? 
        
            <Redirect to={{
                pathname: "/scanTruck",
            }} /> 
            
          
            :
            
      
  <>
        <Navbar />  

        <CheckModal />
            
</>

}
        </>

     );
}
 
export default Checklist;