import Lottie from 'react-lottie';
import scanTruckLottie from '../../../assets/lotties/lf20_maxyrepx.json'
import { useDispatch } from 'react-redux';
import { useState,useEffect } from 'react';
import { scanTruckNfc } from '../../../api/jpApi';



 const ScanTruck = ({type}) => {

  const dispatch = useDispatch()

  const [nfc,setNfc] = useState(null)

  const [nfcState,setState] = useState(null)


  const handleSubmit = (nfc) => {
    console.log(nfc)
    const  data = {
        'id':nfc.replace(/:/g,""),
        'type':type
      }
      dispatch(scanTruckNfc(data))
  }
  

  // const handleSubmit = () => {
  //   if (nfc !== null ){
  //   const nfcS = nfc.replace(/:/g,"");
  //   const  data = {
  //       'id':nfcS,
  //       'type':type
  //     }
  //     dispatch(scanTruckNfc(data))

  //   }
  //   console.log('submitting')
  // }

  const startScanning = () => {

    if ('NDEFReader' in window){
  
    const reader = new window.NDEFReader();
    const contr = new AbortController();

    new Promise  (async (resolve,reject)=>{
      try {
        await reader.scan({signal: contr.signal});
        // console.log('Started')

    
        reader.addEventListener("reading", ({ message, serialNumber }) => {
    
          // const nfcSerial = `${serialNumber}`;
          // console.log('nfcs serial is '+nfcSerial)
          
          // setNfc(nfcSerial)
      
          contr.abort()
          handleSubmit(`${serialNumber}`)
          
        });
        
  
      } catch (error) {
          // alert(error)
        console.log(error)
      }
    })

 


  }else{
    console.log('Not Found')
    setState('Not Supported')
  }
    

    }


  useEffect(() => {

    startScanning()
    
    }, [])	






  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: scanTruckLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

     return ( 
        <>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

        

            <div className="w-full text-center pt-3">
                <p className="font-bold text-2xl text-gray-600 ">Scan Truck's NFC <br />  {nfcState === 'Not Supported' && <span className="text-xs font-bold border border-blue-600 mt-3 text-blue-600 ml-3 inline-flex py-1 px-3  rounded align-middle "> NFC Not Supported </span> }  </p>
            </div>

                  <div className="px-4 w-full   flex  items-center  mt-1 text-left">                         
                  <Lottie 
                options={defaultOptions}
                height={400}
                width={400}
                />                 
                        

                    </div>

                   {/* <div className="w-full lg:w-1/2 mx-auto pb-4">
                <input onChange={

(e)=>{
  console.log(e.target.value)
  setNfc(e.target.value)
}

                } type="text" placeholder="Enter NFC" className="p-3 my-3 border border-1 rounded   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-4 px-3 w-full border-gray-200" />

                <button onClick={()=>handleSubmit(nfc)} className="my-3 font-bold bg-blue-500 text-sm py-3 text-white w-full lg:w-2/3 mx-auto ">Submit NFC</button>

              </div> */}
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40"></div>
</>
      );
 }
  
 export default ScanTruck;