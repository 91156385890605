import Lottie from 'react-lottie';
import authenticateNfc from '../../../assets/lotties/46347-nfc-processing.json'

const AuthTruck = () => {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: authenticateNfc,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    

    return ( 
        <>
        <div
      className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
            
        <div className="border-1 p-3 border-blue-400 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

     

            <div className="w-full text-center">
                <p className="text-lg font-bold">Authenticating Truck</p>
            </div>

                  <div className="flex items-center px-4 w-full pb-3    mt-4 text-left">                          
                  <Lottie 
                                options={defaultOptions}
                                height={400}
                                width={400}
                                />   

                    </div>
      



        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40"></div>
</>
     );
}
 
export default AuthTruck;