import {Route,Redirect} from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import { loginStates } from '../../features/loginSlice';
import { userDetailsAPI } from '../../api/loginApi';
import { useEffect } from 'react';
const PrivateRoute = ({children,...rest}) => {
    
    const isAuth = useSelector(loginStates).isAuth   

    const dispatch  = useDispatch()
    // const isAuth = true


    useEffect(() => {
        !isAuth && 
        localStorage.getItem('jp_token') &&
        dispatch(userDetailsAPI())
      }, [isAuth])

    return (
        <Route
        {...rest}
        render={({ location }) =>
            isAuth ? (
             <> {children} </>
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            )
        }
    />  
    );
}
 
export default PrivateRoute;