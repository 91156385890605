import { useEffect,useState } from 'react';
import Lottie from 'react-lottie';
import { useSelector,useDispatch } from 'react-redux';
import { fetchCheckList } from '../../../api/jpApi';
import loadingLottie from '../../../assets/lotties/lf30_editor_dzzrxhzp.json'
import { checklistSubmit, jpState,clearSuccessTruck } from '../../../features/jpSlice';
import { Redirect } from 'react-router';

const CheckModal = () => {


    const dispatch = useDispatch()

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

      const [list,setList] = useState(null)

      const jpStates  = useSelector(jpState)

      const truck = jpStates.truck
      const loading = jpStates.loading
      const checkItems = jpStates.checkItems
      const checkstation = jpStates.checkstation
      const checklist = jpStates.checklist
    

      useEffect(() => {
            dispatch(fetchCheckList())
      }, [])

      useEffect(() => {
        checkItems !== null && 
        setList(checkItems.reduce((acc,res)=> {
            return [...acc,{'id':res.id,'item':res.item,is_passed:null,remarks:''}]
        },[]))
      }, [checkItems])




      const [remarks,setRemarks] = useState(null)

      const handleSubmit = () => {
            
        // fetching if empty
        const emptyF = list.filter(x => x.is_passed == null || !x.is_passed && x.remarks.length < 1)


        console.log(emptyF)

        emptyF.length == 0 && 
          dispatch(checklistSubmit({remarks:remarks,items:list,checkstation:checkstation}))
      } 

    return ( 
        <>
        {
            checklist !== null ?
                <Redirect to={{
                    pathname: "/driver",
                 }} /> 
            :
     
      
        

            <>

        <div className="w-full overflow-y-scroll h-screen">

     

            <div
          className="justify-center  items-center flex overflow-x-hidden overflow-y-auto  fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-2 mx-auto w-full md:w-1/3 lg:w-1/3 xl:w-1/3  px-3">                 
                
            <div className="border-1 lg:p-3  py-6 border-blue-400 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
    
            {/* body */}


            {/* checklist area */}
        
        {!loading 
        
        ? 

        <div className="w-full">

<div className="w-full my-2 text-left px-3 px-3 py-3 flex justify-between">
        <p className="font-bold text-2xl text-gray-600 uppercase border-b py-1">{truck}</p>
        <p className="cursor-pointer" onClick={()=>dispatch(clearSuccessTruck())} ><span className="material-icons-outlined">
close
</span></p>
</div>
    

    <div className="w-full my-2 text-left px-3">
        <p className="font-bold text-lg text-gray-600 uppercase ">Checkpoint CheckLists</p>
    </div>


            <div className="w-full mt-3 flex p-3 flex-wrap overflow-y-scroll max-h-96">


{list !== null && 
<>

{list.map(l=>
    <div className="w-full  my-2 px-2 overflow-y-scroll max-h-48 " key={l.id}>
    <div className="shadow-sm py-4 px-3 border border-blue-100 rounded-sm flex-col lg:px-6">

    <div className="w-full flex justify-between pb-2">
    <p className="text-sm font-bold text-gray-800 uppercase">{l.item}</p>

<div className="">
    
    <button onClick={()=>{l.is_passed=true;setList([...list])}} className={`py-2 px-3 rounded text-sm font-bold ${ l.is_passed != null && l.is_passed ?  'border-green-600 text-white bg-green-500' : 'text-gray-400 border border-gray-400'}  mx-1`}>YES</button>
    

    <button onClick={()=>{l.is_passed=false;setList([...list])}} className={`py-2 px-3 rounded text-sm font-bold ${ l.is_passed != null && ! l.is_passed ? 'border-red-600 text-white bg-red-500' : 'text-gray-400 border border-gray-400'  }  mx-1`}>NO</button>
    

</div>
    </div>


{  l.is_passed != null && !l.is_passed && 
        <div className="w-full lg:w-1/2 ml-auto border-t pt-2">
        <div className="text-gray-500 w-full text-sm  my-3 text-left leading-relaxed">
                               <label className="font-bold text-base my-1" >Enter Reason</label> 
                               <input 
                               onChange={(e)=>{
                                    l.remarks = e.target.value
                                    setList([...list])
                               }}
                               
                               type="text" className="border border-1 rounded-sm  designation border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-3 px-3 w-full border-gray-200 " />
                             </div>
       
   </div>

}




        {/* <input type="checkbox" onChange={()=>{l.is_passed=!l.is_passed;setList([...list])}}  className="checkbox" /> */}
    </div>
</div>    
    )}



</>
}

</div>


{list != null && 
    <>
    <div className="w-full my-3 px-3  overflow-y-scroll">
    <div className="w-full my-1 text-gray-600 uppercase font-bold text-left">Summary</div>
    <textarea name="" id=""  rows="2" className=" p-3 border border-1 rounded-lg   border-gray-200 mt-1 focus:outline-none focus:ring-1 focus:border-gray-400 focus:ring-gray-400 focus:ring-opacity-400 py-2 px-3 w-full border-gray-200" onChange={(e)=>setRemarks(e.target.value)} ></textarea>
</div>


<div className="my-2 w-full mx-auto lg:w-5/6 ">
    <button className="focus:outline-none w-full py-3  text-lg font-bold text-white bg-blue-600 rounded-lg" onClick={handleSubmit} >Submit</button>
</div>
</>

}


        </div>


:

// display loading lottie

<div className="container my-2 mx-auto ">
    <div className="my-2 text-center font-bold text-gray-600 text-2xl">Generating CheckList </div>
    <div className="w-full flex items-center">
              <Lottie 
                            options={defaultOptions}
                            height={200}
                            width={200}
                            />   
    </div>
</div>

    

        }
          
    
    
    
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40"></div>


           </div>
    </>

}
</>

     );
}
 
export default CheckModal;