import axios from "axios"
import { clearChecklist, fetchError, fetchPending, resetError, resetJP, successFetch, successSubmit, truckAuth, truckFailed, truckSuccess } from "../features/jpSlice"
import { loginFailed, resetFailed } from "../features/loginSlice"

const apiUrl = process.env.REACT_APP_API_URL

const token = localStorage.getItem('jp_token')

export const scanTruckNfc = (data)=> dispatch => {
    console.log('set data is .......')
    console.log(data)

    const token = localStorage.getItem('jp_token')
   
    return new Promise (async (resolve,reject)=> {
        console.log(token)
        try {
            dispatch(truckAuth())
            const res = await axios.post(apiUrl+'jp/scan_vehicle/',data,{headers: {'Authorization': `Token ${token}`}})
            if (res.status === 200){
                dispatch(truckSuccess(res.data))
            }else{
                dispatch(truckFailed(res.data.msg))
                setTimeout(() => {
                    dispatch(resetError())
                }, 3000);
            }
    }catch(e) {
        console.log(e)
        dispatch(truckFailed('Error Found'))
        setTimeout(() => {
            dispatch(resetError())
        }, 3000);
 
    }
    })
} 




// export const SubmitChecklist = (checklist) => dispatch => {
    
// }

export const fetchCheckList  = () => dispatch => {
    return new Promise (async (resolve,reject) => {
        dispatch(fetchPending())
        try {
            const res = await axios.get(apiUrl+'jp/clist')
            if (res.status === 200) {
                dispatch(successFetch(res.data))
            }
        }catch(e){
            dispatch(fetchError('Request Timeout'))
            setTimeout(() => {
                dispatch(resetError())
            }, 3000);
        }
    })
}

export const checklistSubmit = (data) => dispatch => {
    return new Promise (async(resolve,reject) => {
        // trigger loading
        dispatch(truckAuth())


        const token = localStorage.getItem('jp_token')
        
        try {

            console.log('submitging checklist')

            const res = await axios.post(apiUrl+'jp/cp_check/',data,{headers:{'Authorization': `Token ${token}`}})
            if(res.status === 201) {
                dispatch(successSubmit())
                setTimeout(() => {
                    dispatch(resetJP())
                }, 3000);
            }
        }catch(e){
            dispatch(fetchError('Error Encountered'))
            setTimeout(() => {
                dispatch(resetFailed())
            }, 3000);
        }
    })
}

export const cancelList = () => dispatch => {
    dispatch(clearChecklist())
}