import {createSlice} from "@reduxjs/toolkit"

export const jpSlice = createSlice({
    name: 'jp',
    initialState: {
        truck:null,
        driver:null,
        checkstation:null,
        checklist:null,
        authTruck:false,
        loading:false,
        failed:false,
        error:null,
        checkItems:null,
        success:false,
    },
    reducers:{
        truckAuth:(state) =>{
            state.loading = true
        },
        truckFailed:(state,action)=>{
            state.loading=false;
            state.failed=true;
            state.error = action.payload
        },
        truckSuccess:(state,action)=>{
            state.loading=false
            state.truck = action.payload.truckName
            state.driver = action.payload.driver
            state.checkstation = action.payload.id
        },
        clearSuccessTruck:(state,payload)=>{
            state.loading=false;
            state.truck=null;
            state.driver=null;
            state.checkstation=null;
        },
        fetchPending:(state,action)=>{
            state.loading = true;
        },
        successFetch:(state,action)=>{
            state.loading = false;
            state.checkItems = action.payload
        },
        fetchError:(state,action)=>{
            state.loading = false;
            state.error = action.payload
            state.failed = true;
        },
        checklistSubmit:(state,action)=>{
            state.checklist = action.payload
        },
        resetError:(state,action)=>{
            state.failed=false
            state.error=null
        },
        clearChecklist:(state)=>{
            state.checklist = null;
            state.loading = false;
        },
        successSubmit:(state)=>{
            state.success = true;
            state.loading = false;
        },
        resetJP:(state)=>{
            state.success=false;
            state.loading=false;
            state.truck=null;
            state.checkstation = null;
            state.checklist = null;
            state.driver=null;
           
        }
        
    }

})


export const {truckAuth,truckFailed,truckSuccess,checklistSubmit,resetError,fetchError,successFetch,fetchPending,successSubmit,resetJP,clearChecklist,clearSuccessTruck }  = jpSlice.actions
export const jpState = (state) => state.jp
export default jpSlice.reducer