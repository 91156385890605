import {configureStore} from "@reduxjs/toolkit"
import userReducer from './features/userSlice'
import authReducer from './features/loginSlice'
import jpReducer from './features/jpSlice'
import driversReducer from './features/driverSlice'

export default configureStore({
    reducer:{
        auth:authReducer,
        user:userReducer,
        jp:jpReducer,
        drivers:driversReducer,
    }
})  